import { Injectable } from "@angular/core";
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserService } from '../Service/UserService';
import {
  Allpaidbills, AlltotalunpiadDocument, Allunpaidbills,
  Biller, GetUnpaidByCreatedDate, PaidOnebiller,
  Totalpiadbillsamount, UnpaidOnebiller
} from "../Model/billerModel";
import { Stakeholders } from "../Model/stakeholderModel";
import { environment } from "../../environments/environment"

@Injectable()
export class CustomerbillerService {
  apiPath: string = environment.apiUrl;
  apiDevpath: string = environment.apiDevUrl;
  apiBillerProd: string = environment.apiBiller; 
  urlQa: string = "https://api.efile.mor.derash.gov.et/";
  urlBillers: string = "https://api.public.derash.gov.et/";
  productionUrl:string = "https://api.billerreport.derash.gov.et/";
 
  constructor(private http: HttpClient, private userservice: UserService) { }
  //get Customer biller services
  getCustomer(): Observable<Biller> {
    debugger
    return this.http.get<Biller>(this.apiBillerProd + "customer/billers"); //apiPath or apiBillerProd
  }
  getAgents(): Observable<Stakeholders> {
    return this.http.get<Stakeholders>(this.apiDevpath + "api/biller/getAgents"); //urlQa or apiDevpath
  }

  // For all apiPath or apiDevpath

  //get one paid biller list
  getPaidOnebiller(
    id: number,
    fromDate: Date,
    toDate: Date
  ): Observable<PaidOnebiller> {
    let headerOption = this.userservice.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': "Bearer " + `${headerOption}`
      })
    };
    //console.log(headerOption);
    return this.http.get<PaidOnebiller>(
      this.apiDevpath + "api/biller" + "/PaidOneBiller" +
      "?biller_id=" +
      id +
      "&fromDate=" +
      fromDate +
      "&toDate=" +
      toDate, httpOptions
    )
  }
  //get one unpaid biller list
  getUnpaidOnebiller(
    id: number,
    fromDate: Date,
    toDate: Date
  ): Observable<UnpaidOnebiller> {
    return this.http.get<UnpaidOnebiller>(  
      this.apiDevpath + "api/biller" + "/unPaidOneBiller" +
      "?biller_id=" +
      id +
      "&fromDate=" +
      fromDate +
      "&toDate=" +
      toDate
    );
  }
  //get all paid bill list
  getAllpaidbills(fromDate: Date,
    toDate: Date): Observable<Allpaidbills> {
    return this.http.get<Allpaidbills>(
      this.apiDevpath + "api/biller" + "/allPaidBills" +
      "?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate
    );
  }
  //get all Unpaid bill list
  getAllunpaidbills(fromDate: Date,
    toDate: Date): Observable<Allunpaidbills> {
    return this.http.get<Allunpaidbills>(
      this.apiDevpath + "api/biller" + "/allUnpaidBills" +
      "?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate
    );
  }
  //get Total piad bills amount
  getTotalpiadbillsamount(fromDate: Date,
    toDate: Date): Observable<Totalpiadbillsamount> {
      debugger
    return this.http.get<Totalpiadbillsamount>(
      this.apiDevpath + "api/biller" + "/totalPaidBillsAmountReport" +
      "?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate
    );
  }
  //get Total Unpiad bills amount
  getAlltotalunpiadDocument(fromDate: Date,
    toDate: Date): Observable<AlltotalunpiadDocument> {
    return this.http.get<AlltotalunpiadDocument>(
      this.apiDevpath + "api/biller" + "/totalUnpaidDocument" +
      "?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate
    );
  }

  ///get Unpaid Biller ByCreated Date 
  getUnpaidByCreatedDate(
    id: number,
    createDate: string
  ): Observable<GetUnpaidByCreatedDate> {
    return this.http.get<GetUnpaidByCreatedDate>(
      this.apiDevpath + "api/biller" + "/GetUnpaidBillerByCreatedDate" +
      "?biller_id=" +
      id +
      "&createdDate=" +
      createDate
    );
  }

  //Update One Biller Range
  UpdateOneBillerRange(id: number,
    fromDate: Date,
    toDate: Date, bill_due_dt: Date)
    : Observable<any> {

    return this.http.put<GetUnpaidByCreatedDate>(this.apiDevpath + "api/biller/update" + "/OneBillerRange" + "?biller_id=" +
      id +
      "&fromDate=" +
      fromDate +
      "&toDate=" +
      toDate, { bill_due_dt });
  }

  //Update One Biller
  UpdateOneBiller(id: number, createdDate: Date,
    bill_due_dt: Date): Observable<any> {
    return this.http.put<GetUnpaidByCreatedDate>(this.apiDevpath + "api/biller/update" + "/oneBiller" + "?biller_id=" +
      id + "&createdDate=" +
      createdDate, { bill_due_dt });
  }

  //Get Totall Paid Monthly Report
  getTotalPaidWeeklyReport() {
    return this.http.get<Totalpiadbillsamount>(this.apiDevpath + "api/biller" + "/totalWeeklyPaidReport");
  }
  //Get Totall Paid Monthly Report
  getTotalPaidMonthlyReport() {
    return this.http.get<Totalpiadbillsamount>(this.apiDevpath + "api/biller" + "/totallPaidMonthlyReport");
  }

}