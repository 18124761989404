import { Component, OnInit } from '@angular/core';
import { CustomerbillerService } from '../Service/billerService';
import { Stakeholders } from '../Model/stakeholderModel';
import { Biller } from '../Model/billerModel';

@Component({
  selector: 'app-billersandagents',
  templateUrl: './billersandagents.component.html',
  styleUrls: ['./billersandagents.component.scss']
})
export class BillersandagentsComponent implements OnInit {
  agents: Stakeholders;
  billers: Biller;
  isCollapsed: false;
  constructor(private billerService: CustomerbillerService) { }

  ngOnInit(): void {
    this.getagents();
    this.getBillers();
  }
  getagents(): void{
     this.billerService.getAgents().subscribe(data => {
        this.agents = data;
        console.log("agents" + this.agents);
     })
  }
  getBillers()  {
    debugger
    this.billerService.getCustomer().subscribe(response => {
      this.billers = response;
      console.log(response);
    }
    );
  }
}
